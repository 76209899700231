define("web/templates/create/region", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 12
              },
              "end": {
                "line": 46,
                "column": 12
              }
            },
            "moduleName": "web/templates/create/region.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 14
                },
                "end": {
                  "line": 52,
                  "column": 14
                }
              },
              "moduleName": "web/templates/create/region.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 12
              },
              "end": {
                "line": 53,
                "column": 12
              }
            },
            "moduleName": "web/templates/create/region.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "map-box-circle", [], ["coordinates", ["subexpr", "@mut", [["get", "model.location", ["loc", [null, [50, 28], [50, 42]]]]], [], []], "radius", ["subexpr", "@mut", [["get", "miToKm", ["loc", [null, [51, 23], [51, 29]]]]], [], []]], 0, null, ["loc", [null, [49, 14], [52, 33]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 10
            },
            "end": {
              "line": 55,
              "column": 10
            }
          },
          "moduleName": "web/templates/create/region.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "map-box-marker", [], ["coordinates", ["subexpr", "@mut", [["get", "model.location", ["loc", [null, [44, 26], [44, 40]]]]], [], []], "markerOptions", ["subexpr", "@mut", [["get", "markerOptions", ["loc", [null, [45, 28], [45, 41]]]]], [], []]], 0, null, ["loc", [null, [43, 12], [46, 31]]]], ["block", "if", [["get", "miToKm", ["loc", [null, [48, 18], [48, 24]]]]], [], 1, null, ["loc", [null, [48, 12], [53, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 12
            },
            "end": {
              "line": 65,
              "column": 12
            }
          },
          "moduleName": "web/templates/create/region.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          dom.setAttribute(el1, "class", "image-uploader__title");
          var el2 = dom.createTextNode("Region Photo");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "image-uploader__text");
          var el2 = dom.createTextNode("Drag & Drop an image here or browse to upload.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["assets/images/admin-icons/image-upload"], ["class", "image-uploader__icon"], ["loc", [null, [63, 14], [63, 98]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 73,
            "column": 0
          }
        },
        "moduleName": "web/templates/create/region.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered container--push-down");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "edit");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "edit-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4, "class", "section-form");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--50");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "for", "region-name");
        dom.setAttribute(el7, "class", "label label--bold");
        var el8 = dom.createTextNode("Region Name ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "ital");
        var el9 = dom.createTextNode("*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--50");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "for", "country");
        dom.setAttribute(el7, "class", "label label--bold");
        var el8 = dom.createTextNode("Country ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "ital");
        var el9 = dom.createTextNode("*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--50");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "for", "zip");
        dom.setAttribute(el7, "class", "label label--bold");
        var el8 = dom.createTextNode("Zip/Postcode ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "ital");
        var el9 = dom.createTextNode("*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("input");
        dom.setAttribute(el7, "id", "zip");
        dom.setAttribute(el7, "type", "text");
        dom.setAttribute(el7, "name", "Zip/Postcode");
        dom.setAttribute(el7, "required", "required");
        dom.setAttribute(el7, "placeholder", "Zip/Postcode");
        dom.setAttribute(el7, "maxLength", "8");
        dom.setAttribute(el7, "pattern", "[a-zA-Z0-9\\s]*");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--50");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "for", "radius");
        dom.setAttribute(el7, "class", "label label--bold");
        var el8 = dom.createTextNode("Radius (mi) ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("span");
        dom.setAttribute(el8, "class", "ital");
        var el9 = dom.createTextNode("*");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "mapbox-map--regions");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "for", "image-input");
        dom.setAttribute(el7, "class", "label label--bold");
        var el8 = dom.createTextNode("Choose an Image");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "submit");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1, 1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [1, 3]);
        var element4 = dom.childAt(element0, [9]);
        var morphs = new Array(10);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 3, 3);
        morphs[3] = dom.createAttrMorph(element3, 'value');
        morphs[4] = dom.createAttrMorph(element3, 'oninput');
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]), 3, 3);
        morphs[6] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element0, [7, 1]), 3, 3);
        morphs[8] = dom.createAttrMorph(element4, 'class');
        morphs[9] = dom.createAttrMorph(element4, 'value');
        return morphs;
      },
      statements: [["element", "action", ["create"], ["on", "submit"], ["loc", [null, [4, 33], [4, 64]]]], ["inline", "focus-input", [], ["id", "region-name", "type", "text", "placeholder", "Region Name", "value", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [8, 87], [8, 97]]]]], [], []], "required", "required"], ["loc", [null, [8, 12], [8, 119]]]], ["inline", "select-menu", [], ["changeSelectProp", ["subexpr", "action", ["changeCountry"], [], ["loc", [null, [13, 31], [13, 55]]]], "content", ["subexpr", "@mut", [["get", "availableCountries", ["loc", [null, [14, 22], [14, 40]]]]], [], []], "type", "obj", "selectedValue", ["subexpr", "@mut", [["get", "selectedCountry", ["loc", [null, [16, 28], [16, 43]]]]], [], []], "required", "required", "selectId", "country", "prompt", "Country"], ["loc", [null, [12, 12], [19, 32]]]], ["attribute", "value", ["get", "model.zip", ["loc", [null, [32, 22], [32, 31]]]]], ["attribute", "oninput", ["subexpr", "action", ["changeZip"], ["value", "target.value"], ["loc", [null, [33, 22], [33, 65]]]]], ["inline", "input", [], ["id", "radius", "type", "text", "placeholder", "Radius (mi)", "value", ["subexpr", "@mut", [["get", "model.radius", ["loc", [null, [37, 76], [37, 88]]]]], [], []], "required", "required"], ["loc", [null, [37, 12], [37, 110]]]], ["block", "map-box", [], ["fitBoundsOnChange", true], 0, null, ["loc", [null, [41, 10], [55, 22]]]], ["block", "image-input", [], ["selectImage", ["subexpr", "action", ["selectImage"], [], ["loc", [null, [60, 39], [60, 61]]]], "supportedFileTypes", ["subexpr", "@mut", [["get", "supportedFileTypes", ["loc", [null, [60, 81], [60, 99]]]]], [], []]], 1, null, ["loc", [null, [60, 12], [65, 28]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "isCreated", ["loc", [null, [68, 41], [68, 50]]]], "btn--success", "btn--success"], [], ["loc", [null, [68, 36], [68, 82]]]]]]], ["attribute", "value", ["get", "btnText", ["loc", [null, [68, 92], [68, 99]]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});