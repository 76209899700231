define('web/mixins/set-lat-lon-controller', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {

  var m = _webConfigEnvironment['default'].mapbox;
  var mapboxPlaces = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/';
  var types = 'address,place,postcode';
  var tokenString = '.json?types=' + types + '&access_token=' + m.accessToken;

  exports['default'] = _ember['default'].Mixin.create({
    _setLatLong: function _setLatLong(zip) {
      var _this = this;

      var country = this.get('model.country');
      var countryMap = {
        US: 'United States',
        CA: 'Canada',
        GB: 'United Kingdom'
      };

      var address = this.get('model.address1') + ' || ' + countryMap[country] + ' || \'\'} ' + this.get('model.city') + ' ' + zip;

      if (zip.length > 4 && zip.length < 9) {
        if (address) {
          (0, _icAjax['default'])(mapboxPlaces + address + tokenString).then(function (resp) {
            var feature = resp.features[0];

            if (!feature) {
              return;
            }

            _this.set('model.location', _ember['default'].Object.create({
              lon: feature.center[0],
              lat: feature.center[1]
            }));

            var state = undefined;

            feature.context.forEach(function (context) {
              if (context.id.slice(0, 6) === 'region') {
                state = context.text;
              }
            });

            var country = feature.place_name.split(', ').pop();
            var countryMap = {
              'United States': 'US',
              'United Kingdom': 'GB'
            };

            _this.set('model.state', _this.get('states')[state] || state);
            _this.set('model.country', countryMap[country] || country);
          });
        }
      }
    }
  });
});