define('web/adapters/user-option', ['exports', 'web/adapters/application'], function (exports, _webAdaptersApplication) {
  exports['default'] = _webAdaptersApplication['default'].extend({
    namespace: 'api/v2',

    updateRecord: function updateRecord(store, type, snapshot) {

      var serializer = store.serializerFor(type.modelName);

      var data = serializer.serialize(snapshot);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      delete data.tmSalesEnabled;

      return this.ajax(url, "PUT", {
        data: { userOption: data },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var serializer = store.serializerFor(type.modelName);

      var data = serializer.serialize(snapshot);

      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      delete data.tmSalesEnabled;

      return this.ajax(url, "POST", {
        data: { userOption: data },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});